// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".scrollingSection-nnk83{position:relative;white-space:nowrap;max-width:100vw;overflow-x:auto;padding:5px 15px 15px 5px;-webkit-overflow-scrolling:touch;scroll-snap-type:mandatory;scroll-snap-type-x:mandatory;scroll-snap-destination:50% 50%}.tileWrapper-3Uzg5{position:relative;display:inline-block;vertical-align:top;margin-left:10px;scroll-snap-align:center;scroll-snap-coordinate:50% 50%}.editorialCard-3kJwN{width:280px;height:350px}.providerCard-1In23{width:300px;height:400px}\n", ""]);
// Exports
exports.locals = {
	"scrollingSection": "scrollingSection-nnk83",
	"tileWrapper": "tileWrapper-3Uzg5",
	"editorialCard": "editorialCard-3kJwN",
	"providerCard": "providerCard-1In23"
};
module.exports = exports;
