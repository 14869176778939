// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".gridsContainer-3r6j2{position:relative;margin:0 auto 70px;margin-bottom:75px}@media (min-width: 1080px){.gridsContainer-3r6j2{margin-bottom:100px}}@media (min-width: 1280px){.gridsContainer-3r6j2{margin-bottom:150px}}.gridSection-D0bb_{grid-gap:20px;width:100%;max-width:1080px;margin:0 auto 20px}\n", ""]);
// Exports
exports.locals = {
	"gridsContainer": "gridsContainer-3r6j2",
	"gridSection": "gridSection-D0bb_"
};
module.exports = exports;
