// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".readingTime-QHefZ{display:block;font-family:\"GT-Walsheim\",sans-serif;color:#fff;opacity:0.9;font-size:14px;margin-bottom:10px}.shadow-doYjw{text-shadow:0 1px 3px rgba(0,0,0,0.1)}\n", ""]);
// Exports
exports.locals = {
	"readingTime": "readingTime-QHefZ",
	"shadow": "shadow-doYjw"
};
module.exports = exports;
