import React, { Children, cloneElement } from 'react';
import { node, number, string, bool } from 'prop-types';
import { assign } from 'lodash';
import reversedChunk from 'helpers/reversedChunk';
import { Grid, GridItem } from '@blendle/lego';
import {
  GRID_ROWS,
  GRID_COLUMNS,
  GRID_TEMPLATE,
  getTemplateVariant,
  mergeGridAnalytics,
} from '../../helpers/gridTemplate';
import {
  MAX_SECTION_WIDTH_PX,
  EDITORIAL_CARD_GRID_TILES_LIMIT,
  PROVIDER_CARD_GRID_LIMIT,
} from '../../constants';
import CSS from './style.scss';

/**
 * Calculate the tile dimensions from the total size of the grid and
 * the grid item's row and column span
 * @param {Number} gridWidth - The total width oif the grid
 * @param {Number} gridHeight - The total height of the grid
 * @param {Number} rowSpan - The amount of rows this grid item spans
 * @param {Number} columnSpan - The amount of columns this grid item spans
 */
function getTileDimensions(gridWidth, gridHeight, rowSpan, columnSpan) {
  const columnWidth = gridWidth / GRID_COLUMNS;
  const rowHeight = gridHeight / GRID_ROWS;

  return {
    width: columnWidth * columnSpan,
    height: rowHeight * rowSpan,
  };
}

function GridSection({ children, innerWidth, sectionId, useProviderCards }) {
  const tilesCount = Children.count(children);
  if (tilesCount < 1) {
    // TODO: Figure out why / when this happens
    return null;
  }

  // Cut the tiles up in chunks of the card type limit, because we only only have templates for grids
  // with tiles up to limit. We stitch these grids together by rendering multiple
  // templates below each other
  const grids = reversedChunk(
    Children.toArray(children),
    useProviderCards ? PROVIDER_CARD_GRID_LIMIT : EDITORIAL_CARD_GRID_TILES_LIMIT,
  );

  // We need to know the templates before we render the first part of the grid, so we can stitch
  // the analytics payload of the grids together
  const gridTemplates = grids.map((gridTiles, nthGrid) =>
    getTemplateVariant(sectionId, gridTiles.length, innerWidth, nthGrid, useProviderCards),
  );
  const gridAnalytics = mergeGridAnalytics(gridTemplates, tilesCount);

  return (
    <span className={CSS.gridsContainer}>
      {grids.map((gridTiles, nthGrid) => {
        const { gridHeight, tilesTemplate } = gridTemplates[nthGrid];
        const gridWidth = Math.min(innerWidth, MAX_SECTION_WIDTH_PX);

        return (
          <Grid
            className={CSS.gridSection}
            template={GRID_TEMPLATE}
            key={`grid-${sectionId}-${nthGrid}`}
            style={{
              height: gridHeight,
            }}
          >
            {gridTiles.map((tileComponent, index) => {
              const [rowSpan, columnSpan, features] = tilesTemplate[index];
              const { width, height } = getTileDimensions(
                gridWidth,
                gridHeight,
                rowSpan,
                columnSpan,
              );

              return (
                <GridItem
                  key={`gridItem-${tileComponent.key}`}
                  row={`span ${rowSpan}`}
                  column={`span ${columnSpan}`}
                >
                  {cloneElement(tileComponent, {
                    // Mutating the current props seems hacky, but for a grid it's actually needed
                    // Because we always want to include the grid template details
                    // In the analytics, without creating a new object reference
                    analytics: assign(tileComponent.props.analytics, {
                      grid: gridAnalytics,
                    }),
                    features: [...tileComponent.props.features, ...features].filter(
                      feature => !!feature,
                    ),
                    tileWidth: width,
                    tileHeight: height,
                  })}
                </GridItem>
              );
            })}
          </Grid>
        );
      })}
    </span>
  );
}

GridSection.propTypes = {
  children: node.isRequired,
  sectionId: string.isRequired,
  innerWidth: number.isRequired,
  useProviderCards: bool.isRequired,
};

export default GridSection;
