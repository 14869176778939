import { string } from 'prop-types';
import { setPropTypes, compose } from '@blendle/recompose';
import altConnect from 'higher-order-components/altConnect';
import TilesStore from 'stores/TilesStore';

export function mapStateToProps({ tilesState }, { itemId, ...props }) {
  const tile = tilesState.tiles.get(itemId);

  if (!tile) {
    return props;
  }

  return { ...props, audioTrackId: tile.audio_track_id };
}

mapStateToProps.stores = { TilesStore };

const enhance = compose(setPropTypes({ itemId: string.isRequired }), altConnect(mapStateToProps));

export default enhance;
