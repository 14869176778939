import { Backdrop } from '@blendle/lego';

export const BRANDING_GRADIENTS = [
  {
    background: Backdrop.red,
    foreground: Backdrop.yellow,
    position: 'topRight',
  },
  {
    background: Backdrop.green,
    foreground: Backdrop.purple,
    position: 'bottomCenter',
  },
  {
    background: Backdrop.lightBlue,
    foreground: Backdrop.green,
    position: 'centerLeft',
  },
  {
    background: Backdrop.purple,
    foreground: Backdrop.yellow,
    position: 'topLeft',
  },
  {
    background: Backdrop.darkBlue,
    foreground: Backdrop.purple,
    position: 'topCenter',
  },
];

export function getVariantByIndex(index) {
  return BRANDING_GRADIENTS[index % BRANDING_GRADIENTS.length];
}

export const brandingVariant = () => {
  let index = 0;
  return () => {
    index += 1;
    return getVariantByIndex(index - 1);
  };
};

export class AutoRotatingBrandingVariants {
  // We need to start at -1 to get the first value on the first call due to a bug in
  // getVariantByIndex()
  nextIndex = -1;

  variants = new Map();

  getVariant(key) {
    const variantForKey = this.variants.get(key);

    // Return the variants stored for this key if it's present
    if (variantForKey) {
      return variantForKey;
    }

    // If we don't have a variant yet, used the next one in the list.
    this.variants.set(key, getVariantByIndex(this.nextIndex));
    this.nextIndex = this.nextIndex + 1;

    return this.getVariant(key);
  }
}
