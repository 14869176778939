import TilesStore from 'stores/TilesStore';
import { getItemFromTiles } from 'selectors/tiles';
import useAlt from 'hooks/useAlt';

const useTile = itemId => {
  const tilesState = useAlt([TilesStore], ([state]) => state);

  return getItemFromTiles(itemId, tilesState);
};

export default useTile;
