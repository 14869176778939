import { isFeaturedSection } from '../selectors/sections';
import { MOBILE_LAYOUT_SCROLLING } from '../constants';
import ScrollingSection from '../components/ScrollingSection';
import WrappingSection from '../components/WrappingSection';
import GridSection from '../components/GridSection';
import FallbackGridSection from '../components/FallbackGridSection';
import FeaturedSection from '../components/FeaturedSection';

const PROVIDER_CARD_SCROLLING_SECTION_BREAKPOINT = 375;

export default function getWrapperComponent({
  sectionId,
  innerWidth,
  isMobileViewport,
  mobileLayout,
  hasCSSGridSupport,
  tilesCount,
  useProviderCards,
}) {
  if (isFeaturedSection(sectionId) && !useProviderCards) {
    return FeaturedSection;
  }

  if (isMobileViewport && mobileLayout === MOBILE_LAYOUT_SCROLLING && tilesCount > 1) {
    // The providerCard are to wide on really small mobile screens,
    // therefor falling back to the WrappingSection in that case
    if (useProviderCards && innerWidth < PROVIDER_CARD_SCROLLING_SECTION_BREAKPOINT) {
      return WrappingSection;
    }

    return ScrollingSection;
  }

  if (isMobileViewport) {
    return WrappingSection;
  }

  return hasCSSGridSupport ? GridSection : FallbackGridSection;
}
