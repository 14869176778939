// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".featuredSection-2sZK3{height:100vh;width:100vw}\n", ""]);
// Exports
exports.locals = {
	"featuredSection": "featuredSection-2sZK3"
};
module.exports = exports;
