// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes slideUp-cZ8pW{from{opacity:0.3;transform:translateY(15px)}to{opacity:1;transform:none}}.placeholderTile-3kqXA{width:100%;height:100%;position:relative;display:inline-block;vertical-align:top;scroll-snap-align:center;scroll-snap-coordinate:50% 50%;border-radius:2px;border:1px solid #eaeaea;opacity:0;animation:slideUp-cZ8pW 0.2s ease-out forwards;background-color:#fff}.index-0-2-AKj{animation-delay:0s}.index-1-2i4fF{animation-delay:.2s}.index-2-1NWgW{animation-delay:.4s}.index-3-gWIfj{animation-delay:.6s}.index-4-2gNSG{animation-delay:.8s}.index-5-Els1X{animation-delay:1s}.index-6-3eHGw{animation-delay:1.2s}.index-7-36G4X{animation-delay:1.4s}.index-8-1yayx{animation-delay:1.6s}.index-9-3S40e{animation-delay:1.8s}.index-10-nKUwl{animation-delay:2s}.contentRows-22Pmx{position:absolute;width:100%;bottom:0;padding:15px}.row-3wOcz{height:15px;background:#eaeaea;margin-bottom:5px}.row1-3C0sn{width:80%}.row2-1T4e_{width:90%}.row3-3_nIs{width:78%}.row4-2Cwzp{width:85%;height:12px;background:rgba(234,234,234,0.5)}.row5-3jDQo{width:50%;height:12px;background:rgba(234,234,234,0.4)}\n", ""]);
// Exports
exports.locals = {
	"placeholderTile": "placeholderTile-3kqXA",
	"slideUp": "slideUp-cZ8pW",
	"index-0": "index-0-2-AKj",
	"index-1": "index-1-2i4fF",
	"index-2": "index-2-1NWgW",
	"index-3": "index-3-gWIfj",
	"index-4": "index-4-2gNSG",
	"index-5": "index-5-Els1X",
	"index-6": "index-6-3eHGw",
	"index-7": "index-7-36G4X",
	"index-8": "index-8-1yayx",
	"index-9": "index-9-3S40e",
	"index-10": "index-10-nKUwl",
	"contentRows": "contentRows-22Pmx",
	"row": "row-3wOcz",
	"row1": "row1-3C0sn",
	"row2": "row2-1T4e_",
	"row3": "row3-3_nIs",
	"row4": "row4-2Cwzp",
	"row5": "row5-3jDQo"
};
module.exports = exports;
