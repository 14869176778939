// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrappingTileContainer-uTilh{margin:0 auto 10px}\n", ""]);
// Exports
exports.locals = {
	"wrappingTileContainer": "wrappingTileContainer-uTilh"
};
module.exports = exports;
