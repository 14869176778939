// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sectionIntro-2TAj7{margin:10px auto 0;font-size:16px;color:#333;max-width:500px}.sectionIntro-2TAj7 li{list-style:disc outside;margin-left:1.1em;text-align:left}.sectionIntro-2TAj7>*{margin-bottom:1em}\n", ""]);
// Exports
exports.locals = {
	"sectionIntro": "sectionIntro-2TAj7"
};
module.exports = exports;
