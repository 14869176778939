// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title-1Be_W{font-family:\"GT-Walsheim\",sans-serif;font-weight:bold;white-space:normal;color:#fff;max-width:520px}.shadow-3c-tj{text-shadow:0 1px 4px rgba(0,0,0,0.1)}.small-3d4zC{font-size:20px;line-height:28px}.medium-1mw2b{font-size:22px;line-height:30px}.large-1sH-O{font-size:24px;line-height:32px}.extraLarge-DQVK2{font-size:30px;line-height:35px}.huge-1-gYb{font-size:45px;line-height:1.2}\n", ""]);
// Exports
exports.locals = {
	"title": "title-1Be_W",
	"shadow": "shadow-3c-tj",
	"small": "small-3d4zC",
	"medium": "medium-1mw2b",
	"large": "large-1sH-O",
	"extraLarge": "extraLarge-DQVK2",
	"huge": "huge-1-gYb"
};
module.exports = exports;
