// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tilesContainer-sH_EI{position:relative;top:0;bottom:0;display:flex;max-width:100%;flex-flow:row wrap;justify-content:center;overflow-y:hidden}.editorialCardContainer-3hOzl{max-height:500px}.providerCardContainer-2Xxll{max-height:410px}.tileWrapper-1sb1n{text-align:left;margin:0 10px 20px}.editorialCard-2uypb{width:350px;height:500px}.providerCard-3has4{width:343px;height:400px}\n", ""]);
// Exports
exports.locals = {
	"tilesContainer": "tilesContainer-sH_EI",
	"editorialCardContainer": "editorialCardContainer-3hOzl",
	"providerCardContainer": "providerCardContainer-2Xxll",
	"tileWrapper": "tileWrapper-1sb1n",
	"editorialCard": "editorialCard-2uypb",
	"providerCard": "providerCard-3has4"
};
module.exports = exports;
