import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import HTMLWithLinks from 'components/HTMLWithLinks';
import CSS from './style.scss';

export default function SectionIntro({ className, ...props }) {
  return <HTMLWithLinks className={classNames(className, CSS.sectionIntro)} {...props} />;
}

SectionIntro.propTypes = {
  className: string,
};

SectionIntro.defaultProps = {
  className: '',
};
