// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".subtitle-3U2Wn{color:#7f7f7f;font-family:\"GT-Walsheim\",sans-serif;font-size:14px}@media (min-width: 600px){.subtitle-3U2Wn{font-size:15px}}\n", ""]);
// Exports
exports.locals = {
	"subtitle": "subtitle-3U2Wn"
};
module.exports = exports;
