// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sectionHeader-2mx0J{max-width:1080px;margin:0 auto;justify-content:space-between;align-items:center}.tilesWrapper-3Cis-{position:relative}.placeholderTile-3XR3Q{width:280px;height:350px;margin-left:10px}\n", ""]);
// Exports
exports.locals = {
	"sectionHeader": "sectionHeader-2mx0J",
	"tilesWrapper": "tilesWrapper-3Cis-",
	"placeholderTile": "placeholderTile-3XR3Q"
};
module.exports = exports;
