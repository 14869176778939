import { getTileManifest, getEntities } from 'selectors/tiles';
import { getProviderId } from 'selectors/manifest';
import { isRead } from 'selectors/tile';
import { getReadingTime, getManifestBody } from 'helpers/manifest';
import { createItemUriFromManifest } from 'helpers/prettyUrl';
import ItemActions from 'actions/ItemActions';
import { ProviderCard } from '@blendle/lego';
import React from 'react';
import { getProviderCardImage } from 'selectors/itemImage';
import Link from 'components/Link';
import { translate } from 'instances/i18n';
import { number, string, shape, bool } from 'prop-types';
import useTile from 'hooks/useTile';
import { getAudioDeeplinkLandingUrl } from 'helpers/audioDeeplinkUrl';
import ManifestDropdownContent from 'components/ManifestDropdown/ManifestDropdownContent';
import Analytics from 'instances/analytics';
import ItemPreferenceActions from 'actions/ItemPreferenceActions';
import { LOCATION_IN_LAYOUT } from 'app-constants';
import BrowserEnv from 'instances/browser_environment';
import useUser from 'hooks/useUser';
import { getContentElements } from 'helpers/providerCard';

const ProviderCardContainer = ({
  itemId,
  tileWidth,
  tileHeight,
  analytics,
  disableHoverAnimation,
}) => {
  const user = useUser();
  const tile = useTile(itemId);

  if (!tile) {
    return null;
  }

  const manifest = getTileManifest(tile);
  const manifestBody = getManifestBody(manifest);
  const itemUrl = createItemUriFromManifest(manifest);
  const readingTime = getReadingTime(manifest.length);

  const postCount = tile.post_count;
  const showNumberOfLikes = postCount >= 10;

  const isPinned = tile.pinned;
  const readLater = {
    isPinned,
    onClick: () => {
      ItemActions.pinItem(user, tile, !isPinned, analytics);
    },
  };

  const audioTrackId = tile.audio_track_id;
  const audio = audioTrackId && {
    buttonCopy: translate('app.manifest.listen'),
    onClick: () => {
      window.location.href = getAudioDeeplinkLandingUrl(audioTrackId);
    },
  };

  const manifestDropdownContent = (
    <ManifestDropdownContent
      itemId={itemId}
      analytics={analytics}
      itemLength={manifest.length}
      date={manifest.date}
      hidePin
      showItemFeedback
    />
  );

  const onManifestDropdownOpen = () => {
    Analytics.track('Open Manifest Dropdown', {
      item: itemId,
      location_in_layout: LOCATION_IN_LAYOUT.MANIFEST_DROPDOWN,
      ...analytics,
    });

    ItemPreferenceActions.fetchPreference(user.id, itemId);
  };

  const imageUrlAndCredits = getProviderCardImage({
    manifest,
    minWidth: tileWidth,
    minHeight: tileHeight,
  });

  const image = imageUrlAndCredits && {
    ...imageUrlAndCredits,
    onError: (error) => {
      window.ErrorLogger.captureException(error);
    },
  };

  return (
    <Link analytics={analytics} href={`/${itemUrl}`}>
      <ProviderCard
        itemId={itemId}
        width={tileWidth}
        height={tileHeight}
        image={image}
        providerId={getProviderId(manifest)}
        readLater={readLater}
        isRead={isRead(tile)}
        {...getContentElements(manifestBody)}
        numberOfLikes={postCount}
        showNumberOfLikes={showNumberOfLikes}
        readingTimeCopy={translate('app.manifest.time.to.read_in_min', readingTime)}
        entities={getEntities(tile)}
        audio={audio}
        manifestDropdownContent={manifestDropdownContent}
        onManifestDropdownOpen={onManifestDropdownOpen}
        disableHoverAnimation={disableHoverAnimation || BrowserEnv.hasTouch()}
      />
    </Link>
  );
};

ProviderCardContainer.propTypes = {
  itemId: string.isRequired,
  tileWidth: number.isRequired,
  tileHeight: number.isRequired,
  analytics: shape({
    internal_location: string,
    section_id: string,
    section_type: string,
    position: number,
    grid: shape({
      total_items: number,
      columns: number,
      rows: number,
      template: string,
    }),
  }),
  disableHoverAnimation: bool,
};

ProviderCardContainer.defaultProps = {
  analytics: {},
  disableHoverAnimation: false,
};

export default ProviderCardContainer;
