import { chunk } from 'lodash';

export default function reversedChunk(array, size) {
  const firstChunkSize = array.length % size;

  if (firstChunkSize !== 0 && array.length > size) {
    return [array.slice(0, firstChunkSize), ...chunk(array.slice(firstChunkSize), size)];
  }

  return chunk(array, size);
}
