import React, { Children } from 'react';
import { node, string, bool } from 'prop-types';
import classNames from 'classnames';
import CSS from './style.scss';

function HorizontalSection({ className, children, useProviderCards }) {
  const tilesArray = Children.toArray(children).filter(child => !!child);

  return (
    <div
      className={classNames(
        CSS.tilesContainer,
        useProviderCards ? CSS.providerCardContainer : CSS.editorialCardContainer,
        className,
      )}
    >
      {tilesArray.map(tileComponent => (
        <div
          className={classNames(
            CSS.tileWrapper,
            useProviderCards ? CSS.providerCard : CSS.editorialCard,
          )}
          key={`horizontal-wrapper-${tileComponent.key}`}
          data-testid="tilewrapper"
        >
          {tileComponent}
        </div>
      ))}
    </div>
  );
}

HorizontalSection.propTypes = {
  children: node.isRequired,
  className: string,
  useProviderCards: bool.isRequired,
};

HorizontalSection.defaultProps = {
  className: '',
};

export default HorizontalSection;
