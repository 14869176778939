// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper-1nRCd{width:100%;height:100%}.content-239m_{position:absolute;width:100%;height:100%;top:0;left:0;border-radius:3px;overflow:hidden;display:flex;flex-direction:column;justify-content:space-between;background-color:rgba(0,0,0,0.1);transition:background-color 0.1s ease-out}.content-239m_:hover{background-color:rgba(0,0,0,0.05)}.content-239m_ a,.content-239m_ button{pointer-events:auto;cursor:auto}.noBackground-GKd6f,.noBackground-GKd6f:hover,.noBackground-GKd6f .body-x7Zk_{pointer-events:none;background:none}.noBorderRadius-lR214{border-radius:0}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-1nRCd",
	"content": "content-239m_",
	"noBackground": "noBackground-GKd6f",
	"body": "body-x7Zk_",
	"noBorderRadius": "noBorderRadius-lR214"
};
module.exports = exports;
