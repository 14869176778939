// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".intro-LxxWj{max-width:520px;margin-top:10px;color:#fff;font-family:\"GT-Walsheim\",sans-serif;font-size:16px;line-height:24px}.large-34TY2{font-size:18px;line-height:1.2}\n", ""]);
// Exports
exports.locals = {
	"intro": "intro-LxxWj",
	"large": "large-34TY2"
};
module.exports = exports;
