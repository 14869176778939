// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-1j3ec{from{opacity:0.3}to{opacity:1}}.sectionTileWrapper-3cPk_{position:relative;display:block;background:white;width:100%;height:100%;border-radius:2px;overflow:hidden;box-shadow:0 2px 8px rgba(0,0,0,0.3);transition:box-shadow 0.3s ease-out}.sectionTile-hMuQW{animation:fadeIn-1j3ec 0.2s ease-out}.noBorderRadius-1mmGN{border-radius:0}.body-2bZBj{bottom:0;background-image:linear-gradient(-180deg, transparent 0%, rgba(0,0,0,0.7) 100%);padding:22px}.bodyLargePadding-a4i2k{padding:0 60px 60px}.noBackground-1LWuy{background:none}.postCount-17ben{color:white}.heartIcon-1uUSu{width:11px;height:10px;float:left;margin-right:4px}.heartIcon-1uUSu path{fill:#ff6255}.readLater-1R4Ig{color:white;display:flex;padding:0;padding:10px;margin:-10px}.readLaterIcon-G1gTn{margin-right:7px}.readLaterIcon-G1gTn svg{width:16px;height:16px}.readLaterIcon-G1gTn path{fill:none;stroke-width:2px;stroke:#fff}.isOnReadingList-1n5Oj path{fill:#fff}.imageCredits-JnMp-{position:absolute;line-height:1;bottom:-10px;right:-10px;text-align:right;font-family:\"GT-Walsheim\",sans-serif;color:#fff;font-size:8px;opacity:0.5}\n", ""]);
// Exports
exports.locals = {
	"sectionTileWrapper": "sectionTileWrapper-3cPk_",
	"sectionTile": "sectionTile-hMuQW",
	"fadeIn": "fadeIn-1j3ec",
	"noBorderRadius": "noBorderRadius-1mmGN",
	"body": "body-2bZBj",
	"bodyLargePadding": "bodyLargePadding-a4i2k",
	"noBackground": "noBackground-1LWuy",
	"postCount": "postCount-17ben",
	"heartIcon": "heartIcon-1uUSu",
	"readLater": "readLater-1R4Ig",
	"readLaterIcon": "readLaterIcon-G1gTn",
	"isOnReadingList": "isOnReadingList-1n5Oj",
	"imageCredits": "imageCredits-JnMp-"
};
module.exports = exports;
