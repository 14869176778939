// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pageSection-3XeRA{position:relative;padding-bottom:60px}@media screen and (min-width: 600px){.pageSection-3XeRA{padding:0 20px 60px}}.fullWidth--UA-O{padding-left:0;padding-right:0;margin-left:0;margin-right:0}.includeVerticalPadding-3OYsG{padding-top:30px;padding-bottom:30px}@media screen and (min-width: 600px){.includeVerticalPadding-3OYsG{padding-top:50px;padding-bottom:50px}}\n", ""]);
// Exports
exports.locals = {
	"pageSection": "pageSection-3XeRA",
	"fullWidth": "fullWidth--UA-O",
	"includeVerticalPadding": "includeVerticalPadding-3OYsG"
};
module.exports = exports;
