import React from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames';
import CSS from './style.scss';

function SectionHeader({ className, hasIntro, ...props }) {
  return (
    <header
      className={classNames(className, CSS.sectionHeader, hasIntro && CSS.centerAlignedOnDesktop)}
      {...props}
    />
  );
}

SectionHeader.propTypes = {
  className: string,
  hasIntro: bool.isRequired,
};

SectionHeader.defaultProps = {
  className: '',
};

export default SectionHeader;
