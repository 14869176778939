import React from 'react';
import { bool, string, number, shape } from 'prop-types';
import { memoize } from 'lodash';
import classNames from 'classnames';
import { hexToRgba } from 'helpers/colors';
import { PROVIDER_CARD_SECTION_BACKGROUND_COLOR } from 'modules/sectionsPage/constants';
import CSS from './style.scss';

const getStyle = memoize(background => {
  if (!background) {
    return {};
  }

  return { background: hexToRgba(background.color, background.opacity || 0.1) };
});

// Only include extra padding if we have a background color that is not white
export const shouldIncludeVerticalPadding = backgroundColor => {
  return (
    backgroundColor &&
    backgroundColor.toLowerCase() !== '#ffffff' &&
    backgroundColor.toLowerCase() !== PROVIDER_CARD_SECTION_BACKGROUND_COLOR
  );
};

export default function SectionWrapper({ className, isFeatured, background, ...props }) {
  return (
    <section
      className={classNames(
        className,
        CSS.pageSection,
        isFeatured && CSS.fullWidth,
        background && shouldIncludeVerticalPadding(background.color) && CSS.includeVerticalPadding,
      )}
      style={getStyle(background)}
      {...props}
    />
  );
}

SectionWrapper.propTypes = {
  className: string,
  isFeatured: bool.isRequired,
  background: shape({
    color: string.isRequired,
    opacity: number,
  }),
};

SectionWrapper.defaultProps = {
  className: '',
  background: undefined,
};
