// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fallbackGridSection-3char{max-height:none;max-width:1110px;margin-left:auto;margin-right:auto;justify-content:center}@media (min-width: 1110px){.fallbackGridSection-3char{justify-content:flex-start}}\n", ""]);
// Exports
exports.locals = {
	"fallbackGridSection": "fallbackGridSection-3char"
};
module.exports = exports;
