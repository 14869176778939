/* eslint-disable react/prop-types */
// Disable prop type linting because we use setPropTypes
import React from 'react';
import { compose, setPropTypes, onlyUpdateForPropTypes, setDisplayName } from '@blendle/recompose';
import { string, arrayOf, oneOf, bool, number } from 'prop-types';
import { memoize } from 'lodash';
import { Columns } from '@blendle/lego';
import { AutoRotatingBrandingVariants } from 'helpers/branding';
import ProviderCardContainer from 'modules/sectionsPage/containers/ProviderCardContainer';
import { INTERNAL_LOCATION } from 'app-constants';
import { isFeaturedSection } from '../../selectors/sections';
import SectionWrapper from '../SectionWrapper';
import TilesWrapper from '../TilesWrapper';
import SectionHeader from '../SectionHeader';
import SectionTitle from '../SectionTitle';
import SectionSubtitle from '../SectionSubtitle';
import SectionIntro from '../SectionIntro';
import {
  MOBILE_LAYOUT_WRAPPING,
  MOBILE_LAYOUTS,
  EDITORIAL_CARD_DEFAULT_WIDTH_PX,
  EDITORIAL_CARD_DEFAULT_HEIGHT_PX,
  PROVIDER_CARD_DEFAULT_WIDTH_PX,
  PROVIDER_CARD_DEFAULT_HEIGHT_PX,
  PROVIDER_CARD_SECTION_BACKGROUND_COLOR,
} from '../../constants';
import SectionTileContainer from '../../containers/SectionTileContainer';
import CSS from './style.scss';

const brandingVariants = new AutoRotatingBrandingVariants();

/**
 * Get the memoized analytics for a section tile.
 * The memoization is done based on the combination of sectionId, itemId and index in section
 * to ensure we don't create a new object reference on each rerender
 */
const getTileAnalytics = memoize(
  (sectionId, itemId, indexInSection, sectionType, internalLocation, numberOfTilesInSection) => ({
    internal_location: internalLocation,
    section_id: sectionId,
    section_type: sectionType,
    position: indexInSection,
    grid: {
      total_items: numberOfTilesInSection,
    },
  }),
  (...args) => args.join('::'),
);

const enhance = compose(
  onlyUpdateForPropTypes,
  setPropTypes({
    sectionId: string.isRequired,
    tilesCount: number.isRequired,
    isLoading: bool.isRequired,
    internalLocation: string,
    sectionType: string.isRequired,
    sectionConsistsOfProviderCards: bool.isRequired,
    areProviderCardsAllowed: bool.isRequired,
    title: string,
    subtitle: string,
    intro: string,
    backgroundColor: string,
    mobileLayout: oneOf(MOBILE_LAYOUTS),
    itemIds: arrayOf(string).isRequired,
    hideSectionAction: bool,
    sectionActionsContainer: (props) => {
      if (!props.hideSectionAction && typeof props.sectionActionsContainer !== 'function') {
        return new Error('sectionActionsContainer is required for sections with actions');
      }

      return null;
    },
  }),
  setDisplayName('PageSection'),
);

export function getBackground({ backgroundColor, useProviderCards, internalLocation }) {
  if (backgroundColor) {
    return {
      color: backgroundColor,
    };
  }

  if (useProviderCards && internalLocation === INTERNAL_LOCATION.TIMELINE_PREMIUM) {
    return {
      color: PROVIDER_CARD_SECTION_BACKGROUND_COLOR,
      opacity: 1,
    };
  }

  return undefined;
}

function PageSection({
  itemIds,
  internalLocation = INTERNAL_LOCATION.TIMELINE_PREMIUM,
  title,
  subtitle,
  intro,
  backgroundColor,
  headerComponent: HeaderComponent,
  hideSectionAction = false,
  sectionId,
  sectionType,
  isLoading,
  tilesCount,
  mobileLayout = MOBILE_LAYOUT_WRAPPING,
  sectionActionsContainer: SectionActionsContainer,
  sectionConsistsOfProviderCards,
  areProviderCardsAllowed,
}) {
  const useProviderCards = areProviderCardsAllowed && sectionConsistsOfProviderCards;

  const TileComponent = useProviderCards ? ProviderCardContainer : SectionTileContainer;

  const defaultSize = useProviderCards
    ? {
        width: PROVIDER_CARD_DEFAULT_WIDTH_PX,
        height: PROVIDER_CARD_DEFAULT_HEIGHT_PX,
      }
    : {
        width: EDITORIAL_CARD_DEFAULT_WIDTH_PX,
        height: EDITORIAL_CARD_DEFAULT_HEIGHT_PX,
      };

  return (
    <SectionWrapper
      isFeatured={isFeaturedSection(sectionId)}
      background={getBackground({
        backgroundColor,
        useProviderCards,
        internalLocation,
      })}
      data-testid="page-section"
    >
      <Columns className={CSS.sectionHeader}>
        {HeaderComponent && <HeaderComponent title={title} subtitle={subtitle} intro={intro} />}
        {!HeaderComponent && (
          <SectionHeader hasIntro={Boolean(intro)}>
            {title && <SectionTitle>{title}</SectionTitle>}
            {subtitle && !intro && <SectionSubtitle>{subtitle}</SectionSubtitle>}
            {intro && <SectionIntro>{intro}</SectionIntro>}
          </SectionHeader>
        )}
        {!hideSectionAction && <SectionActionsContainer sectionId={sectionId} />}
      </Columns>
      <TilesWrapper
        sectionId={sectionId}
        mobileLayout={mobileLayout}
        isLoading={isLoading}
        tilesCount={tilesCount}
        useProviderCards={useProviderCards}
      >
        {itemIds.length > 0 &&
          itemIds.map((itemId, index) => (
            <TileComponent
              key={`tile-${itemId}`}
              itemId={itemId}
              brandingVariants={brandingVariants}
              tileWidth={defaultSize.width}
              tileHeight={defaultSize.height}
              sectionId={sectionId}
              indexInSection={index}
              analytics={getTileAnalytics(
                sectionId,
                itemId,
                index,
                sectionType,
                internalLocation,
                itemIds.length,
              )}
            />
          ))}
      </TilesWrapper>
    </SectionWrapper>
  );
}

export const PageSectionComponent = PageSection;
export default enhance(PageSection);
