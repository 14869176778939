// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".sectionHeader-2Ora8{padding:0 15px 15px}@media (min-width: 600px){.sectionHeader-2Ora8{padding-left:0}}@media (min-width: 600px){.centerAlignedOnDesktop-B0-b0{padding:0 15px 15px;margin:0 auto;text-align:center}}\n", ""]);
// Exports
exports.locals = {
	"sectionHeader": "sectionHeader-2Ora8",
	"centerAlignedOnDesktop": "centerAlignedOnDesktop-B0-b0"
};
module.exports = exports;
