import React from 'react';
import { node, bool } from 'prop-types';
import classNames from 'classnames';
import {
  EDITORIAL_CARD_DEFAULT_WIDTH_PX,
  EDITORIAL_CARD_DEFAULT_HEIGHT_PX,
  PROVIDER_CARD_MIN_WIDTH_PX,
  PROVIDER_CARD_DEFAULT_HEIGHT_PX,
} from 'modules/sectionsPage/constants';
import CSS from './style.scss';

function ScrollingSection({ children, useProviderCards }) {
  return (
    <div className={CSS.scrollingSection}>
      {children.length > 0 &&
        React.Children.map(children, (child, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`tilewrapper-${index}`}
            className={classNames(
              CSS.tileWrapper,
              useProviderCards ? CSS.providerCard : CSS.editorialCard,
            )}
            data-testid="tilewrapper"
          >
            {/* Disabling the hover shadow of the card since the shadow is cut off by the overflow
            effect of the scrolling container */}
            {React.cloneElement(child, {
              disableHoverAnimation: useProviderCards,
              tileWidth: useProviderCards
                ? PROVIDER_CARD_MIN_WIDTH_PX
                : EDITORIAL_CARD_DEFAULT_WIDTH_PX,
              tileHeight: useProviderCards
                ? PROVIDER_CARD_DEFAULT_HEIGHT_PX
                : EDITORIAL_CARD_DEFAULT_HEIGHT_PX,
            })}
          </div>
        ))}
    </div>
  );
}

ScrollingSection.propTypes = {
  children: node.isRequired,
  useProviderCards: bool.isRequired,
};

export const ScrollingSectionComponent = ScrollingSection;
export default React.memo(ScrollingSection);
