// Slightly modified https://stackoverflow.com/a/5624139
export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const longHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(longHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {};
}

export function hexToRgba(hex, alpha = 1) {
  const { r, g, b } = hexToRgb(hex);

  const a = Math.min(1, Math.max(0, alpha));
  return `rgba(${[r, g, b, a].join()})`;
}
