// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title-1quUW{color:#000;font-family:\"GT-Walsheim\",sans-serif;font-size:20px;font-weight:700}.title-1quUW:first-letter{text-transform:uppercase}@media (min-width: 600px){.title-1quUW{font-size:24px}}@media (min-width: 1080px){.title-1quUW{font-size:32px}}\n", ""]);
// Exports
exports.locals = {
	"title": "title-1quUW"
};
module.exports = exports;
