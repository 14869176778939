import React from 'react';
import { string, bool } from 'prop-types';
import withAudioTrackId from 'higher-order-components/withAudioTrackId';
import styledModule from 'helpers/styledModule';
import AudioIcon from 'components/icons/Audio';
import renderNothingIfCapabilityIsLocked from 'higher-order-components/renderNothingIfCapabilityIsLocked';
import { FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST } from 'app-constants';
import { compose } from '@blendle/recompose';
import { getAudioDeeplinkLandingUrl } from 'helpers/audioDeeplinkUrl';
import CSS from './style.scss';

const IconButton = styledModule.button(CSS.iconButton);

const TrackIcon = ({ audioTrackId, itemId, capabilityIsLocked, ...rest }) => {
  if (!audioTrackId) {
    return null;
  }

  const onClickButton = (e) => {
    // Prevent the reader from being opened
    e.preventDefault();

    window.location.href = getAudioDeeplinkLandingUrl(audioTrackId);
  };

  return (
    <IconButton {...rest} onClick={onClickButton}>
      <AudioIcon />
    </IconButton>
  );
};

TrackIcon.propTypes = {
  itemId: string.isRequired,
  audioTrackId: string,
  capabilityIsLocked: bool,
};

TrackIcon.defaultProps = {
  audioTrackId: undefined,
  capabilityIsLocked: false,
};

export default compose(
  renderNothingIfCapabilityIsLocked(FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST),
  withAudioTrackId,
)(TrackIcon);
