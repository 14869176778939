// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".background-J9p2P{width:100%;height:100%}.brandingShape-39Ynk{background:rgba(0,0,0,0.1) !important;transition:transform 0.5s ease-out}.brandingTopRight-1ZxBl{transform:rotate(30deg);width:110%;right:-85%;height:400px;top:-140px}.brandingBottomCenter-3ZhVq{transform:rotate(15deg);width:110%;left:-10%;height:500px;bottom:-250px}.brandingCenterLeft-nl7Xh{transform:rotate(30deg);width:110%;left:-75%;height:400px;bottom:30px}.brandingTopLeft-dkBq2{transform:rotate(60deg);width:105%;left:-60%;height:400px;top:-200px}.brandingTopCenter-paQmW{transform:rotate(60deg);width:110%;right:-30%;height:400px;top:-300px}@media (min-width: 600px){.backgroundColor-jtZEj:hover .brandingTopRight-1ZxBl{transform:rotate(30deg) translate3d(-15px, 15px, 10px) !important}.backgroundColor-jtZEj:hover .brandingBottomCenter-3ZhVq{transform:rotate(15deg) translate3d(0, -15px, 10px) !important}.backgroundColor-jtZEj:hover .brandingCenterLeft-nl7Xh{transform:rotate(30deg) translate3d(15px, -15px, 10px) !important}.backgroundColor-jtZEj:hover .brandingTopLeft-dkBq2{transform:rotate(60deg) translate3d(15px, 15px, 10px) !important}.backgroundColor-jtZEj:hover .brandingTopCenter-paQmW{transform:rotate(60deg) translate3d(15px, 15px, 10px) !important}}\n", ""]);
// Exports
exports.locals = {
	"background": "background-J9p2P",
	"brandingShape": "brandingShape-39Ynk",
	"brandingTopRight": "brandingTopRight-1ZxBl",
	"brandingBottomCenter": "brandingBottomCenter-3ZhVq",
	"brandingCenterLeft": "brandingCenterLeft-nl7Xh",
	"brandingTopLeft": "brandingTopLeft-dkBq2",
	"brandingTopCenter": "brandingTopCenter-paQmW",
	"backgroundColor": "backgroundColor-jtZEj"
};
module.exports = exports;
