// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".body-1x56-{position:relative;bottom:0;padding:0 20px 20px;z-index:0}.largePadding-3OdHF{padding:0 60px 60px}.content-1b03X{z-index:1;position:relative}.overlayGradient-2tLqE{position:absolute;width:100%;height:120%;left:0;bottom:0;background-image:linear-gradient(to top, rgba(0,0,0,0.7) 30%, transparent)}.bottomLeftGradient-1DIFr{position:static}.bottomLeftGradient-1DIFr .overlayGradient-2tLqE{background-image:linear-gradient(30deg, rgba(0,0,0,0.8), transparent 65%)}\n", ""]);
// Exports
exports.locals = {
	"body": "body-1x56-",
	"largePadding": "largePadding-3OdHF",
	"content": "content-1b03X",
	"overlayGradient": "overlayGradient-2tLqE",
	"bottomLeftGradient": "bottomLeftGradient-1DIFr"
};
module.exports = exports;
